import { graphql, Link } from 'gatsby'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Helmet from '../components/Helmet'
import SectionNav from '../components/SectionNav'

import styles from './index.module.scss'

import rswoosh from './header-red-swoosh.svg'
import yswoosh from './header-yellow-swoosh.svg'
  
interface IIndexPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      }
    },
    pages: any,
    books: any
  }
}
  
export const indexPageQuery = graphql`
  query IndexPageQuery {
    pages: allMdx {
      totalCount
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
          }
        }
      }
    }
    books: allBooksJson (
      sort: { order: ASC, fields: [order] },
    ) {
      edges {
        node {
          title
          chapters {
            pages {
              path
            }
            title
          }
        }
      }
    }
  }
`
  
export default (props: IIndexPageProps) => {
  return (<div>
    <Helmet />
    <Header logoOnly={false} />
    <div className={styles.landingBrand}>
      <Container>
        <Row>
          <Col>
            <h1>Jakobs Japanisch</h1>
          </Col>
        </Row>
      </Container>
    </div>
    <div className={styles.landingAction}>
      <img className={styles.rswoosh} src={rswoosh} alt=''/>
      <Container>
        <Row>
          <Col>
            <h2>Willst du Japanisch lernen?</h2>
            <p>Wie du anfangen kannst, was du brauchst, erste Tipps und mehr.</p>
            <Link to={'/japanisch-lernen-wie-anfangen/'}>Japanisch anfangen</Link>
          </Col>
        </Row>
      </Container>
    </div>
    <img className={styles.yswoosh} src={yswoosh} alt=''/>
    <SectionNav details={true} className={styles.footerNavigation}/>
    <Footer />
  </div>)
};